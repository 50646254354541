<template>
  <app-overlay>
    <db-searchable-table v-model="filters" @changed="getData" :items="documents" :fields="fields" :total-rows="total" searchable>
      <template #customName="{ item }">
        <span v-if="item.docTemplate && item.docTemplate.name"> {{ item.docTemplate.name }}</span>
        <span v-else-if="item.customName"> {{ item.customName }} </span>
        <span v-else> - </span>
      </template>
      <template #addedBy="{ item }">
        <span v-if="item.addedBy && item.addedBy.user && item.addedBy.user.fullname && item.addedBy.user.role">
          <app-user-info variant="secondary" :title="item.addedBy.user.fullname" :alt_title="item.addedBy.user.role.value" />
        </span>
        <span v-else> - </span>
      </template>
      <template #actions="{ item }">
        <app-dropdown>
          <b-dropdown-item @click="getFile(item)">
            <feather-icon class="mr-50" icon="EyeIcon" />
            <span>Görüntüle</span>
          </b-dropdown-item>
        </app-dropdown>
      </template>
    </db-searchable-table>
  </app-overlay>
</template>

<script>
export default {
  data() {
    return {
      filters: {},
    };
  },
  computed: {
    fields() {
      if (this.windowWidth < 767) {
        return [
          { key: "customName", label: "Dosya Adı" },
          { key: "fileGroup.value", label: "Dosya Türü" },
          { key: "actions", label: "Eylemler" },
        ];
      }
      return [
        ...(this.selectedBranch ? [] : [{ key: "branch.name", label: "Sicil" }]),
        { key: "customName", label: "Dosya Adı" },
        { key: "fileGroup.value", label: "Dosya Türü" },
        { key: "addedBy", label: "Yükleyen" },
        { key: "createdAt", label: "Yüklenme Tarihi", formatDateTime: true },
        { key: "actions", label: "Eylemler" },
      ];
    },
    windowWidth() {
      return this.$store.getters["app/windowWidth"];
    },
    company() {
      return this.$store.getters?.selectedCompany?._id;
    },
    documents() {
      return this.$store.getters.documents?.data;
    },
    total() {
      return this.$store.getters.documents?.total;
    },
  },
  methods: {
    setQuery() {
      return {
        ...this.filters,
        sortBy: "isActive",
        sortType: "desc",
        "fileGroup.group": "document",
        company: this?.company,
        isArchived: false,
      };
    },
    getData() {
      this.$store.dispatch("getWsFiles", {
        query: this.setQuery(),
        actionType: "setDocuments",
      });
    },
    archiveFile(id) {
      this.$confirm({ message: "Kayıt silinecek emin misiniz?" }, () => {
        this.$axios
          .patch(`/company/ws-files/${id}/archive`, { loading: "table" })
          .then(() => {
            this.getData();
            this.$emitter.$emit("Notification", {
              variant: "success",
              title: "İşlem Başarılı",
              message: "Dosya sistemden başarıyla silindi.",
            });
          })
          .catch((error) => {
            console.log(error);
          });
      });
    },
    getFile(item) {
      this.$axios
        .get(`/company/ws-files/${item?._id}`, { loading: "table", responseType: "blob" })
        .then((result) => {
          const blob = new Blob([result.data], { type: result.headers["content-type"] });
          const url = window.URL.createObjectURL(blob);
          const newWindow = window.open(url, "_blank");
          if (!newWindow) {
            alert("Pop-up engelleyiciniz aktif durumdadır. Dökümanı görüntüleyebilmek için lütfen engelleyiciyi kapatınız.");
          }
        })
        .catch((err) => {
          console.log(err);
          this.$emitter.$emit("Notification", { title: "Hata Oluştu", variant: "danger" });
        });
    },
  },
  destroyed() {
    this.$store.commit("setDocuments", []);
  },
};
</script>
